.user-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.35);
    z-index: 99;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    max-height: 100vh;
    overflow: auto;

    box-sizing: border-box;
    padding: 25px 0;

    &.loading {
        .modal-body {
            h1 {
            }
        }
    }

    .modal-body {
        background: #fff;
        background: #010d1a;
        border-radius: 6px;
        border: 1px solid rgba($color: #fff, $alpha: 0.2);
        // max-width: 1000px;
        // width: 75%;
        // min-width: 300px;

        // width: 300px;
        width: 100%;
        max-width: 600px;

        min-height: 60vh;
        max-height: 90vh;

        box-sizing: border-box;
        // min-height: 300vh;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .modal-head {
            width: 100%;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            box-sizing: border-box;
            border-bottom: 2px solid rgba($color: #fff, $alpha: 0.4);

            h1 {
                font-size: 24px;
                width: auto;
            }
            .close {
                border: 1px solid #8e8e8e;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                position: relative;
                transition: 0.25s;
                cursor: pointer;
                &::after,
                &::before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 12px;
                    height: 1.5px;
                    background: #8e8e8e;
                    transition: 0.25s;
                }
                &::after {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
                &::before {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
                &:hover {
                    border-color: #fff;
                    &::after,
                    &::before {
                        background: #fff;
                    }
                }
            }
        }
        .modal-info {
            // flex: 1 0 auto;
            // background: red;

            &::-webkit-scrollbar-track {
                transition: 0.25s;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: rgba(194, 226, 255, 1);
            }

            &::-webkit-scrollbar {
                transition: 0.25s;

                width: 7px;
                background-color: rgba(194, 226, 255, 1);
            }

            &::-webkit-scrollbar-thumb {
                transition: 0.25s;

                background: linear-gradient(
                    103.53deg,
                    #0d6adf -4.37%,
                    #0da9df 104.99%
                );
            }
            width: 100%;
            box-sizing: border-box;
            padding: 15px 20px;
            overflow: auto;

            .user-selected-table {
                width: 100%;
                // overflow-y: auto;
                // overflow-x: auto;
                // overflow-y: visible;
                height: auto;
                // max-height: 500px;
                // min-height: 200px;
                margin-top: 20px;
                .head-info {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .page-control {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 10px;
                    }
                    .page-filters {
                        min-width: 150px;
                    }
                }

                .table-holder {
                    max-width: 100%;
                    overflow-x: auto;

                    &::-webkit-scrollbar-track {
                        transition: 0.25s;
                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                        background-color: rgba(194, 226, 255, 1);
                    }

                    &::-webkit-scrollbar {
                        transition: 0.25s;

                        // width: 7px;
                        height: 5px;
                        background-color: rgba(194, 226, 255, 1);
                    }

                    &::-webkit-scrollbar-thumb {
                        transition: 0.25s;

                        background: linear-gradient(
                            103.53deg,
                            #0d6adf -4.37%,
                            #0da9df 104.99%
                        );
                    }
                    &.grabbed {
                        cursor: grab;
                        * {
                            cursor: grab;
                        }
                    }
                    table {
                        // max-width: 600px !important;
                        // min-width: 1px !important;
                    }
                }
            }
            .btns {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                flex-wrap: wrap;
                .title {
                    width: 100%;
                }
                button {
                    flex: 1 0 0;
                    min-width: 0;

                    &.active {
                        background: red;
                    }
                }
            }
            // max-height: calc(100% - 200px);
            .name {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 20px;
                font-size: 21px;
                .logo {
                    width: 50px;
                    height: 50px;
                    position: relative;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .small-info {
                padding: 0;
                list-style: none;
                margin: 0;
                color: rgba($color: #fff, $alpha: 0.6);
                margin-top: 10px;
                margin-bottom: 20px;

                // text-transform: lowercase;
                li {
                    font-size: 13px;
                    margin-bottom: 7px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    span {
                        color: rgba($color: #fff, $alpha: 1);
                        font-size: 14px;
                    }
                }
            }
            .inputs {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 10px;
                margin-bottom: 20px;
                .label {
                    font-size: 14px;
                }
                input {
                    width: 100%;
                }
            }

            .adit {
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 10px 20px;

                // flex-direction: column;
                flex-wrap: wrap;

                .adit-info {
                    color: rgba($color: #fff, $alpha: 0.6);
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 5px;

                    span {
                        color: rgba($color: #fff, $alpha: 1);
                        font-size: 14px;
                        font-weight: bold;
                        // letter-spacing: 1px;
                    }
                }
            }
        }
        .modal-btm {
            width: 100%;
            // height: 100px;
            border-top: 2px solid rgba($color: #fff, $alpha: 0.4);

            box-sizing: border-box;
            padding: 10px 20px;
            margin-top: auto;
            // background: blue;
            button {
                width: 100%;
            }
        }
        // h1 {
        //     font-size: 31px;
        //     margin-bottom: 5px;
        //     line-height: 100%;
        // }
        // .small-info {
        //     margin-bottom: 20px;
        //     ul {
        //         padding: 0;
        //         list-style: none;
        //         margin: 0;
        //         color: rgba($color: #fff, $alpha: 0.6);
        //         // text-transform: lowercase;
        //         li {
        //             font-size: 13px;
        //             margin-bottom: 7px;
        //             &:last-child {
        //                 margin-bottom: 0;
        //             }
        //             span {
        //                 color: rgba($color: #fff, $alpha: 1);
        //                 font-size: 14px;
        //             }
        //         }
        //     }
        // }

        .own-pick {
            position: relative;
            width: 100%;
            .own-head {
                width: 100%;
                box-sizing: border-box;
                padding: 12px;
                border-radius: 8px;
                border: none;
                cursor: default;
                background: linear-gradient(
                    103.11deg,
                    rgba(255, 255, 255, 0.084) -10.1%,
                    rgba(112, 112, 112, 0.084) 110.43%
                );
                outline: none;
                font-family: "Inter", sans-serif;
                color: #f5f5f5;
            }
            .own-list {
                position: absolute;
                left: 0;
                top: 99%;
                z-index: 3;

                width: 100%;
                background: linear-gradient(
                    103.11deg,
                    rgba(255, 255, 255, 0.084) -10.1%,
                    rgba(112, 112, 112, 0.084) 110.43%
                );
                backdrop-filter: blur(10px);
                transition: 0.45s;
                box-sizing: border-box;
                border: 1px solid rgba($color: #fff, $alpha: 0.07);
                border-radius: 3px;

                opacity: 0;
                pointer-events: none;
                .itm {
                    box-sizing: border-box;
                    padding: 10px;
                    position: relative;
                    cursor: pointer;
                    transition: 0.25s;
                    &:hover {
                        opacity: 0.5;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 1px;
                        background: rgba($color: #fff, $alpha: 0.12);
                    }
                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
            &:hover {
                .own-list {
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }

        .refs {
            width: 100%;

            background: #121d29;
            box-sizing: border-box;
            padding: 10px;
            border-radius: 6px;
            border: 1px solid rgba($color: #fff, $alpha: 0.3);
            .levels {
                display: flex;
                align-items: center;
                justify-content: space-between;
                // margin-bottom: 20px;
                width: 100%;
                .level {
                    width: 30%;
                    margin-right: 16px;
                    &:last-child {
                        margin-right: 0;
                    }
                    .head {
                        margin-bottom: 8px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        padding: 12px;
                        padding-top: 0;
                        position: relative;
                        border-bottom: 0.5px solid #d7d7d7;
                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 6px;
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background: #ffbe0a;
                        }

                        span {
                            &:first-child {
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 20px; /* 142.857% */
                                letter-spacing: -0.28px;
                                text-transform: uppercase;
                                color: var(--accent-blue-head);
                                opacity: 0.9;
                            }
                            &:last-child {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 3px;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 20px;
                                letter-spacing: -0.28px;
                                text-transform: uppercase;
                                color: var(--accent-blue-2);
                                .hint {
                                    position: relative;
                                    svg {
                                        * {
                                            transition: 0.25s;
                                            // fill: ;
                                        }
                                    }

                                    &:hover {
                                        .tip {
                                            transform: translateX(-30px)
                                                translateY(-5px);
                                            opacity: 1;
                                        }
                                    }
                                }
                                .tip {
                                    position: absolute;
                                    padding: 20px 16px;
                                    background: var(--bg-black);
                                    color: rgba(255, 255, 255, 0.85);
                                    width: 370px;
                                    box-shadow: 0px 16px 16px 0px
                                        rgba(0, 0, 0, 0.08);
                                    border-radius: 7px;
                                    z-index: 3;
                                    bottom: 100%;
                                    left: 0%;
                                    transform: translateX(-30px)
                                        translateY(15px);

                                    transition: 0.25s;
                                    opacity: 0;
                                    pointer-events: none;

                                    &:after {
                                        content: "";
                                        margin: 0 0.5em;
                                        display: inline-block;
                                        border: 5px solid transparent;
                                        border-top: 6px solid var(--bg-black);
                                        border-bottom: 0 none;

                                        position: absolute;
                                        top: 100%;
                                        left: 30px;
                                        transform: translateX(-50%);
                                    }

                                    .tip-head {
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 18px; /* 128.571% */
                                        letter-spacing: -0.28px;
                                    }
                                    .tip-text {
                                        font-size: 13px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 21px;
                                        letter-spacing: -0.13px;
                                        margin-top: 10px;
                                    }
                                }
                            }
                        }
                    }
                    .users {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-bottom: 8px;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px; /* 142.857% */
                        letter-spacing: -0.28px;
                        text-transform: uppercase;
                        color: var(--accent-blue-head);
                        svg {
                            margin-right: 5px;
                        }
                    }
                    .money {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px; /* 142.857% */
                        letter-spacing: -0.28px;
                        text-transform: uppercase;
                        color: var(--accent-blue-head);
                        svg,
                        span {
                            margin-right: 5px;
                        }
                    }
                    &:nth-child(3) {
                        .users,
                        .money {
                            svg {
                                * {
                                    fill: #01cbeb;
                                }
                            }
                        }
                        .head {
                            &::before {
                                background: #01cbeb;
                            }
                        }
                    }
                    &:nth-child(2) {
                        .users,
                        .money {
                            svg {
                                * {
                                    fill: #5fe03f;
                                }
                            }
                        }
                        .head {
                            &::before {
                                background: #5fe03f;
                            }
                        }
                    }
                }
            }
        }
    }
}
