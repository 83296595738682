.admins {
    .width-content {
        .head {
            box-sizing: border-box;
            padding: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: rgba(1, 13, 26, 0.7);
            /* Gray/Clear/700 */

            border-bottom: 1px solid #474747;
            .title {
                // display: flex;
                .top {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 26px;
                    color: #fff;
                    margin-bottom: 8px;
                }
                .bot {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    opacity: 0.6;
                }
            }
        }

        .big-block {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 32px 0;
            border-bottom: 1px solid #474747;
            &:last-child {
                border-bottom: 0;
            }
            .left {
                width: 35%;
                .title {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 26px;
                    color: #fff;
                    margin-bottom: 8px;
                }
                .desc {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #fff;
                    opacity: 0.6;
                }
            }
            .right {
                width: 65%;
                // background: red;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                // border-radius: 20px 20px 0px 0px;

                .user-list {
                    // background: red;
                    width: 100%;
                    max-width: 690px;
                    border-radius: 20px;
                    border: 1px solid #474747;
                    overflow: hidden;
                    .list-head {
                        background: $gradient-inputs;
                        border-bottom: 1px solid #474747;
                        .key {
                            flex: 1px;
                            &:first-child {
                                min-width: 260px;
                                // background: red;
                            }
                            &:nth-child(2) {
                                min-width: 150px;
                                // background: blue;
                            }
                            &:nth-child(3) {
                                min-width: 150px;
                                // background: pink;
                            }
                            &:last-child {
                                // flex: 0;
                                min-width: 130px;
                            }
                        }
                    }
                    .list-item,
                    .list-head {
                        box-sizing: border-box;
                        padding: 12px 24px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    .list-item {
                        border-bottom: 1px solid #474747;
                        &:last-child {
                            border-bottom: none;
                        }

                        .key-name {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            min-width: 260px;
                            // background: red;

                            .img {
                                width: 48px;
                                height: 48px;
                                margin-right: 12px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                            .name {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                                box-sizing: border-box;
                                span {
                                    &:first-child {
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 20px;
                                        margin-bottom: 4px;
                                    }
                                    &:last-child {
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 24px;
                                        opacity: 0.6;
                                    }
                                }
                            }
                        }
                        .key {
                            &:nth-child(2) {
                                min-width: 150px;
                                // background: blue;
                            }
                            &:nth-child(3) {
                                min-width: 150px;
                                // background: pink;
                            }
                            &:last-child {
                                // flex: 0;
                                min-width: 130px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                span {
                                    margin-right: 24px;
                                    cursor: pointer;
                                    transition: 0.25s;
                                    &:hover {
                                        opacity: 0.6;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
