// button:not(.Toastify__close-button) {
button:not([type="button"]) {
    padding: 10px 16px;
    outline: none;
    border-radius: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-text;
    cursor: pointer;
    position: relative;
    transition: 0.25s;
    user-select: none;

    background: transparent;
    svg {
        margin-left: 14px;
    }
    span {
        width: 100%;
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: $gradient-main;
        opacity: 1;
        transition: 0.25s;
        border-radius: 10px;
        left: 0;
        top: 0;
        z-index: 0;
    }
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 10px;
        padding: 2px;
        background: $gradient-main;
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
        transition: 0.25s;
        opacity: 0;
    }

    &.dec {
        background: red;
        &::before,
        &::after {
            display: none;
        }
    }

    &:hover {
        background: transparent;
        border-color: transparent;
        &::before {
            opacity: 1;
        }
        &::after {
            opacity: 0;
        }
    }
}
