.r-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(11, 24, 61, 0.8);
    backdrop-filter: blur(8px);
    z-index: 123;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: 0.25s;
    pointer-events: none;
    opacity: 0;
    &.show_pop {
        opacity: 1;
        pointer-events: all;
    }
    .popup-container {
        padding: 24px 30px 30px;
        border-radius: 12px;
        background: var(--White, #fff);
        box-shadow: 0px 20px 32px -16px rgba(30, 53, 117, 0.15),
            0px 0px 3px -1px rgba(0, 0, 0, 0.25);
        max-width: 550px;

        color: var(--text-black);

        .title {
            color: #35598f;
            margin-bottom: 20px;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px; /* 166.667% */
            letter-spacing: -0.24px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
        }

        .text {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 160% */
            letter-spacing: -0.3px;
            color: #545b70;

            b {
                font-weight: 600;
                color: var(--text-almost-black);
            }
            .accent-blue {
                color: var(--accent-blue-2);
            }
            .accent-red {
                color: var(--accent-red);
            }
            a {
                text-decoration: dotted;
            }
        }
        .btns {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // justify-content: center;
            gap: 40px;
            margin-top: 32px;
        }
        button {
            padding: 8px 36px;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 160% */
            letter-spacing: -0.3px;
            background: #35598f;
            box-shadow: 0px 6px 16px 0px rgba(33, 76, 194, 0.2);
            color: #fff;
            border-radius: 8px;
            border: 1px solid transparent;
            transition: 0.25s;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            &::after,
            &::before {
                display: none;
            }
            &.confirm {
                &:hover {
                    // background: rgba(
                    //     $color: var(--accent-blue-2),
                    //     $alpha: 0.65
                    // );
                    opacity: 0.65;
                }
            }
            &.desagree {
                padding: 0;
                background: transparent;
                color: #adb6cf;

                box-shadow: 0px 6px 16px 0px rgba(33, 76, 194, 0);

                &:hover {
                    color: var(--text-black);
                }
            }
        }
    }
}
