input {
    box-sizing: border-box;
    padding: 12px;
    border-radius: 8px;
    border: none;
    background: $gradient-inputs;
    outline: none;
    font-family: "Inter", sans-serif;
    color: $color-text;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
