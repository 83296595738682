.page-block {
    .width-content {
        .bg-block {
            // background: linear-gradient(
            //         103.53deg,
            //         #000c19 -4.37%,
            //         rgba(13, 169, 223, 0.3) 104.99%
            //     ),
            //     linear-gradient(0deg, #010d1a, #010d1a);
            // border: 1px solid rgba(13, 169, 223, 0.3);
            box-shadow: 1px 1px 1px 1px rgba(13, 169, 223, 0.3);
            background: #010d1a;
            box-sizing: border-box;
            padding: 24px;
            border-radius: 20px;
        }
        .info-block {
            margin-top: 24px;
            &:first-child {
                margin-top: 0;
            }
            .title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
                .text {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    font-weight: 600;
                    font-size: 24px;
                    line-height: 26px;
                    /* identical to box height, or 108% */

                    /* Secondary */

                    background: linear-gradient(
                        103.53deg,
                        #0d6adf -4.37%,
                        #0da9df 104.99%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    svg {
                        margin-right: 12px;
                    }
                }
            }

            .line-list {
                width: 100%;
                background: linear-gradient(
                    103.11deg,
                    rgba(255, 255, 255, 0.063) -10.1%,
                    rgba(112, 112, 112, 0.063) 110.43%
                );
                border-radius: 10px;
                padding: 0px 16px;
                box-sizing: border-box;
                .item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #474747;
                    padding: 20px 0;
                    &:last-child {
                        border-bottom: none;
                    }
                    .key {
                        flex: 100%;
                        display: flex;
                        align-items: center;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        // max-width: 40%;
                        svg {
                            margin-right: 5px;
                        }
                        &:nth-child(2) {
                            text-align: center;
                            justify-content: center;

                            // max-width: 40%;
                        }
                        &:last-child {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            max-width: 20%;
                        }

                        .status {
                            // background: #009966;
                            padding: 6px 8px 6px 10px;
                            box-sizing: border-box;
                            border: 1px solid #009966;
                            color: #009966;
                            border-radius: 100px;
                            transition: 0.25s;
                            svg {
                                * {
                                    transition: 0.25s;
                                }
                            }
                            &.status-minus {
                                color: #ea3829;
                                border-color: #ea3829;
                                svg {
                                    transform: rotate(180deg) scaleX(-1);
                                    * {
                                        stroke: #ea3829;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .select-name {
            position: relative;
            z-index: 9;
            opacity: 1;
            max-width: 300px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .headlist {
                position: relative;
                opacity: 0.8;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                background: linear-gradient(
                    103.11deg,
                    rgba(255, 255, 255, 0.084) -10.1%,
                    rgba(112, 112, 112, 0.084) 110.43%
                );
                border-radius: 8px;
                box-sizing: border-box;
                padding: 12px 8px;
                svg {
                    margin-left: 18px;
                }
            }
            .list {
                transform: translateX(20px) translateY(-50px);
                position: absolute;
                right: 0;
                opacity: 0;
                // visibility: hidden;
                pointer-events: none;
                transition: all 0.3s ease-in-out;
                top: 40px;
                //
                background: #010d1a;
                width: 240px;
                backdrop-filter: blur(40px);
                border-radius: 10px;
                z-index: 999;
                list-style: none;
                box-sizing: border-box;
                padding: 0;
                margin: 0;
                padding: 4px;

                max-height: 175px;
                overflow: auto;
                li {
                    box-sizing: border-box;
                    padding: 8px;
                    color: #8e8e8e;
                    transition: 0.25s;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    cursor: pointer;
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(
                            103.11deg,
                            rgba(255, 255, 255, 0.084) -10.1%,
                            rgba(112, 112, 112, 0.084) 110.43%
                        );
                        border-radius: 6px;
                        transition: 0.25s;
                        opacity: 0;
                        z-index: -1;
                    }
                    &.active {
                        color: #f5f5f5;
                        &::before {
                            background: linear-gradient(
                                103.53deg,
                                #000c19 -4.37%,
                                rgba(13, 169, 223, 0.3) 104.99%
                            );
                            opacity: 1;
                        }
                    }

                    &:hover {
                        color: #f5f5f5;
                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }
            .headlist:hover + .list,
            .list:hover {
                opacity: 1;
                // visibility: visible;
                pointer-events: all;
                // transform: translateX(0);
            }
        }

        .flex-split {
            width: 100%;
            display: flex;
            align-items: stretch;
            justify-content: space-between;

            flex-wrap: wrap;

            .left {
                width: 48%;
            }
            .right {
                width: 48%;
            }
        }
    }
}
