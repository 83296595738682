.actions {
    .width-content {
        .head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;
            .title {
                font-weight: 600;
                font-size: 24px;
                line-height: 40px;
            }

            .state {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;

                .inputs {
                    margin-right: 24px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    label {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        color: #8e8e8e;
                        margin-bottom: 8px;
                        // gap: 12px;
                    }
                    input {
                        border: 2px solid transparent;
                        transition: 0.25s;

                        &.active {
                            border-color: #03dac5;
                        }
                    }
                }

                .datePick {
                    background: $gradient-inputs;
                    box-sizing: border-box;
                    // padding: 10px;
                    width: 44px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border-radius: 8px;
                    transition: 0.25s;
                    border: 2px solid transparent;
                    &.active {
                        border-color: #03dac5;
                    }
                }
            }
        }

        .actions-container {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start;
            .item {
                width: 100%;
                display: flex;
                box-sizing: border-box;
                align-items: center;
                justify-content: stretch;
                padding: 16px;
                border-top: 1px solid #474747;
                gap: 0 20px;

                .img {
                    width: 64px;
                    height: 64px;
                    background: $gradient-inputs;
                    margin-right: 24px;
                    border-radius: 8px;
                }
                .key {
                    // width: calc((100% - 64px - 24px) / 3);
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;

                    flex: 1 0 auto;
                    min-width: 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    max-width: calc(100% / 5);

                    &.pointer {
                        cursor: pointer;
                        transition: 0.25s;
                        &:hover {
                            opacity: 0.75;
                        }
                    }
                    .text {
                        // max-width: 350px;
                        letter-spacing: 1px;
                        line-height: 140%;
                        max-width: 90%;
                    }
                }
            }
        }
    }
}
