.confirm-window,
.typical-layout {
    transition: 0.55s;

    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10009;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // cursor: pointer;

    opacity: 1;
    visibility: visible;
    .close-lar {
        background: rgba($color: #000000, $alpha: 0.6);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
        transition: 0.25s;
        &:hover {
            background: rgba($color: #000000, $alpha: 0.3);
        }
    }
    .confirm-block {
        z-index: 2;
        width: 100%;
        max-width: 408px;
        box-sizing: border-box;
        padding: 24px;
        background: #010d1a;
        box-shadow: 0px 180px 72px rgba(25, 25, 26, 0.01),
            0px 101px 61px rgba(25, 25, 26, 0.05),
            0px 45px 45px rgba(25, 25, 26, 0.09),
            0px 11px 25px rgba(25, 25, 26, 0.1),
            0px 0px 0px rgba(25, 25, 26, 0.1);
        border-radius: 20px;
        border: 1px solid rgba(172, 172, 172, 0.3);

        // border-image-source: linear-gradient(
        //     96.49deg,
        //     rgba(255, 255, 255, 0.3) -5.67%,
        //     rgba(172, 172, 172, 0.3) 101.82%
        // );

        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 500;
            font-size: 24px;
            line-height: 26px;
            margin-bottom: 32px;
            .close {
                cursor: pointer;
                transition: 0.25s;

                &:hover {
                    opacity: 0.6;
                }
            }
        }
        .btns {
            display: flex;
            align-items: center;
            justify-content: space-between;
            button {
                width: 48%;

                &.cancel {
                    &::after {
                        background: #ea3829;
                        opacity: 0;
                    }
                    &::before {
                        opacity: 1;
                        background: #ea3829;
                    }
                    &:hover {
                        &::after {
                            opacity: 1;
                        }
                        &::before {
                            opacity: 0;
                            // background: #ea3829;
                        }
                    }
                }

                &.big-one {
                    width: 100%;
                }
                // &.confirm {
                // }
            }
        }
    }
}
.closed-window {
    // transition: 0.25s;

    // right: 0;
    opacity: 0;
    visibility: hidden;
}

.typical-layout {
    .avail {
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 24px;
        span {
            color: #009966;
        }
    }
    h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
    }
    .inputs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 16px;
        label {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 8px;
        }
        input {
            border: 1px solid rgba(172, 172, 172, 0.3);
            width: 100%;

            // border-image-source: linear-gradient(96.49deg, rgba(255, 255, 255, 0.3) -5.67%, rgba(172, 172, 172, 0.3) 101.82%);
        }
    }
    .radio-list {
        .inp {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 16px;
            input {
                visibility: hidden;
                width: 1px;
                height: 1px;
                z-index: -1;
            }
            label {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                .self-radio {
                    width: 22px;
                    height: 22px;
                    background: #aaaaaa;
                    border-radius: 50%;
                    margin-right: 15px;
                    position: relative;
                    transition: 0.25s;
                    &::before {
                        content: "";
                        position: absolute;
                        width: calc(100% - 4px);
                        height: calc(100% - 4px);
                        left: 2px;
                        top: 2px;
                        background: #010d1a;
                        border-radius: 50%;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        background: linear-gradient(
                            103.53deg,
                            #000c19 -4.37%,
                            rgba(13, 169, 223, 0.3) 104.99%
                        );
                        transition: 0.25s;
                        opacity: 0;
                        border-radius: 50%;
                    }

                    &.checked {
                        background: linear-gradient(
                            103.53deg,
                            #000c19 -4.37%,
                            rgba(13, 169, 223, 0.3) 104.99%
                        );
                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
