.manage-page {
    .width-content {
        .flex-split {
            // justify-content: space-around;
            gap: 20px;
            flex-wrap: wrap;
            .left {
                // width: 74%;
                // min-width: 760px;
                // max-width: 900px;
                min-width: 720px;
                flex: 1 0 0;
                .bg-block {
                    // min-width: 620px;
                }
            }
            .right {
                // width: 25%;
                // min-width: 370px;
                // max-width: 500px;
                min-width: 0;
                flex: 1 0 0;
            }
            h2 {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                svg {
                    margin-right: 14px;
                }
            }

            .inputs {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin-right: 28px;
                &:last-child {
                    margin-right: 0;
                }
                label {
                    color: #8e8e8e;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 8px;
                }

                &.mini {
                    input {
                        max-width: 100px;
                        // text-align: center;
                    }
                }
            }
            .timer {
                box-sizing: border-box;
                padding: 12px 21px;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #f5f5f5;
                background: linear-gradient(
                    103.11deg,
                    rgba(255, 255, 255, 0.084) -10.1%,
                    rgba(112, 112, 112, 0.084) 110.43%
                );
                border-radius: 8px;
                cursor: default;
                margin-right: 24px;
                border: 1px solid rgba(172, 172, 172, 0.3);
            }
            .mini-block {
                width: 100%;
                box-sizing: border-box;
                background: linear-gradient(
                    103.11deg,
                    rgba(255, 255, 255, 0.084) -10.1%,
                    rgba(112, 112, 112, 0.084) 110.43%
                );
                border-radius: 10px;
                padding: 16px;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                border: 1px solid rgba(172, 172, 172, 0.06);

                .slider-timer {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-start;
                    margin: 0 auto;
                    .lvl {
                        width: 32px;
                        transition: 0.25s;
                        opacity: 0;
                        background: transparent;
                        opacity: 1;
                        border: 1px solid rgba($color: #fff, $alpha: 0.1);
                        border-radius: 3px 3px 0 0;

                        &.show {
                            opacity: 1;
                        }
                        &:nth-child(1) {
                            height: 10px;

                            &.show {
                                background: #7ce063;
                            }
                        }
                        &:nth-child(2) {
                            height: 15px;
                            &.show {
                                background: #5fe03f;
                            }
                        }
                        &:nth-child(3) {
                            height: 20px;
                            &.show {
                                background: #4bc22f;
                            }
                        }
                        &:nth-child(4) {
                            height: 25px;
                            &.show {
                                background: #ffa51f;
                            }
                        }
                        &:nth-child(5) {
                            height: 30px;
                            &.show {
                                background: #ff9900;
                            }
                        }
                        &:nth-child(6) {
                            height: 35px;
                            &.show {
                                background: #f58301;
                            }
                        }
                        &:nth-child(7) {
                            height: 40px;
                            &.show {
                                background: #ff7847;
                            }
                        }
                        &:nth-child(8) {
                            height: 45px;
                            &.show {
                                background: #eb3b2f;
                            }
                        }
                        &:nth-child(9) {
                            height: 50px;

                            &.show {
                                background: #cc0808;
                            }
                        }
                    }
                }

                .range-block {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;
                    margin-right: 24px;

                    // background: #000;

                    .range-container {
                        width: 100%;
                        position: relative;
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-start;
                        .hint {
                            background: linear-gradient(
                                103.11deg,
                                rgba(255, 255, 255, 0.063) -10.1%,
                                rgba(112, 112, 112, 0.063) 110.43%
                            );
                            backdrop-filter: blur(10px);
                            box-sizing: border-box;
                            padding: 8px;
                            width: 61px;
                            text-align: center;
                            position: absolute;
                            bottom: calc(100% + 23px);
                            border-radius: 4px;
                            left: 50%;
                            transition: 0.25s;
                            opacity: 0;
                            transform: translateX(-50%);

                            &::after {
                                content: "";
                                position: absolute;

                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 15px 7.5px 0 7.5px;
                                border-color: #282f37 transparent transparent
                                    transparent;
                                top: 100%;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }

                        input {
                            width: 100%;
                            -webkit-appearance: none;
                            width: 100%;
                            height: 6px;
                            padding: 0;
                            background: #eae9ed;
                            outline: none;
                            opacity: 0.7;
                            background-image: linear-gradient(
                                90deg,
                                #64ff33 -4.64%,
                                #ff6e33 92.19%
                            );
                            background-size: 70% 100%;
                            background-repeat: no-repeat;
                            transition: opacity 0.25s;

                            &:hover {
                                opacity: 1;
                            }

                            &::-webkit-slider-thumb {
                                -webkit-appearance: none;
                                appearance: none;
                                width: 24px;
                                height: 24px;
                                background: #fff;
                                border-radius: 50%;
                                cursor: pointer;
                                border: 3px solid #e18537;
                            }
                            &::-moz-range-thumb {
                                width: 24px;
                                height: 24px;
                                background: #fff;
                                border-radius: 50%;
                                cursor: pointer;
                                border: 3px solid #e18537;
                            }
                            &:hover {
                                & + .hint {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    span {
                        &:first-child {
                            margin-right: 8px;
                        }
                        &:last-child {
                            margin-left: 8px;
                        }
                    }
                }

                .capacity-container {
                    width: 100%;
                    position: relative;
                    background: rgba(103, 119, 247, 0.2);
                    // opacity: 0.2;
                    height: 36px;
                    border-radius: 8px;
                    overflow: hidden;
                    .label {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                    }
                    .load {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        background: #6777f7;
                        transition: 0.25s;
                        &.another {
                            background: orangered;
                        }
                    }
                    .max,
                    .min {
                        position: absolute;
                        top: 50%;
                        right: 12px;
                        transform: translateY(-50%);
                    }
                    .min {
                        right: auto;
                        left: 12px;
                        z-index: 2;
                    }
                }
            }
        }
        .bottom {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            margin-top: 24px;
            flex-wrap: wrap;
            gap: 20px;
            .bg-block {
                width: 48%;
                h2 {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 26px;
                }
            }
        }
        .row {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
            & > div:first-child {
                margin-right: 20px;
            }
        }
        .select-name {
            z-index: 999;
        }
        button {
            width: 100%;
        }
        h3 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
            }
        }
        .checkbox {
            width: 32px;
            height: 20px;
            position: relative;
            transition: 0.25s;
            background: #aaaaaa;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
            &::after {
                content: "";
                position: absolute;
                left: 2px;
                top: 2px;
                width: 16px;
                height: 16px;
                background: #ffffff;
                border-radius: 50%;
                transition: 0.25s;
            }
            &::before {
                content: "";
                position: absolute;
                left: -100%;
                top: 0;
                width: 100%;
                height: 100%;
                transition: 0.25s;
                background: linear-gradient(
                    103.53deg,
                    #000c19 -4.37%,
                    rgba(13, 169, 223, 0.3) 104.99%
                );
            }

            &.active {
                &::after {
                    left: calc(100% - 18px);
                }
                &::before {
                    left: 0%;
                }
            }
        }
        .right {
            flex-wrap: wrap;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            align-content: flex-start;
            gap: 20px;
            width: auto;
            .bg-block {
                // margin-bottom: 24px;
                max-width: 380px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            h3 {
                .select-name {
                    min-width: 140px;
                    .headlist {
                        min-width: 140px;
                    }
                    * {
                        color: #8e8e8e;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
            h2 {
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
}
