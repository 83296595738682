$gradient-block-bg: linear-gradient(
    103.11deg,
    rgba(0, 0, 0, 0.126) -10.1%,
    rgba(0, 0, 0, 0.126) 110.43%
);
$gradient-block-border: linear-gradient(
    163.39deg,
    rgba(3, 218, 197, 0.3) 22.98%,
    rgba(255, 255, 255, 0.06) 77.02%
);
// $gradient-inputs: linear-gradient(
//     96.49deg,
//     rgba(255, 255, 255, 0.3) -5.67%,
//     rgba(172, 172, 172, 0.3) 101.82%
// );
$gradient-inputs: linear-gradient(
    103.11deg,
    rgba(255, 255, 255, 0.084) -10.1%,
    rgba(112, 112, 112, 0.084) 110.43%
);
// $gradient-main: linear-gradient(
//     103.53deg,
//     #000c19 -4.37%,
//     rgba(13, 169, 223, 0.3) 104.99%
// );
$gradient-main: linear-gradient(
    124.53deg,
    #000c19 -4.37%,
    rgba(13, 169, 223, 0.3) 100.99%
);
$gradient-second: linear-gradient(103.53deg, #0d6adf -4.37%, #0da9df 104.99%);

$color-notify: #ea3829;
$color-accent-1: #03dac5;
$color-accent-2: #009966;
$color-main-bg: #010d1a;
// $color-second-bg: #474747;
$color-second-bg: #000812;
$color-text: #f5f5f5;
$container-border: rgba(71, 71, 71, 1);
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");

html {
    padding: 0;
    margin: 0;
    font-family: "Inter", sans-serif;
    background: $color-second-bg;
    color: $color-text;
    // background: $color-second-bg;
    // color: $color-text;

    body {
        padding: 0;
        margin: 0;
        // cursor: url("./cursor_100.png"), auto;
        &::-webkit-scrollbar-track {
            transition: 0.25s;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: rgba(194, 226, 255, 1);
        }

        &::-webkit-scrollbar {
            transition: 0.25s;

            width: 7px;
            background-color: rgba(194, 226, 255, 1);
        }

        &::-webkit-scrollbar-thumb {
            transition: 0.25s;

            background: linear-gradient(
                103.53deg,
                #0d6adf -4.37%,
                #0da9df 104.99%
            );
        }

        #root {
            width: 100%;
            box-sizing: border-box;
            padding: 0;
            margin: 0;
            .Toastify__toast-container--top-right {
                top: 110px;
                line-height: 21px;
            }
            .Toastify__toast-theme--dark {
                // background: $color-main-bg;
                // linear-gradient(112.98deg, #3d7cbc -4.82%, #0dbbdf 110.92%)
                background: #121212;
            }
            // background: red;
            .position {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                input {
                    font-family: "Inter", sans-serif;
                }
                @import "./sidebar.scss";

                .holder {
                    // @media (max-width: 768px) {
                    //     width: 100%;
                    //     min-width: 100%;
                    //     max-width: 100%;
                    // }
                    width: calc(100% - 240px);
                    // width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    box-sizing: border-box;
                    @media (max-width: 768px) {
                        width: 100%;
                        min-width: 100%;
                        max-width: 100%;
                    }

                    // @import "./button.scss";

                    .width-content {
                        // width: calc(1440px - 240px);
                        width: 100%;
                        box-sizing: border-box;
                        padding: 24px;
                    }
                    .page-block {
                        width: 100%;
                        @import "./pageMiniHead.scss";
                    }
                    @import "./header.scss";
                    @import "./PageBlock.scss";

                    @import "./stata/dashboard.scss";
                    @import "./actions/index.scss";
                    @import "./admins/index.scss";
                    @import "./manage/index.scss";
                    @import "./moneyFlow/index.scss";
                    @import "./inputs.scss";
                    // @import "./Settings/index.scss";
                    // @import "./Referrals/index.scss";
                    // @import "./Main/index.scss";
                    // @import "./Map/index.scss";
                    // @import "./Help/index.scss";

                    .date-pick {
                        position: fixed;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100vh;
                        // background: #000;
                        display: flex;
                        align-items: center;
                        z-index: 101010;
                        justify-content: center;
                        .close {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: 0;
                            background: rgba($color: #000000, $alpha: 0.66);
                            transition: 0.25s;
                            &:hover {
                                background: rgba($color: #000000, $alpha: 0.46);
                            }
                        }
                        button {
                            padding: inherit;
                            outline: none;
                            border-radius: inherit;

                            background: transparent;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            border: 0;
                            padding: 0;
                            line-height: 3em;
                            height: 3em;
                            text-align: center;
                            color: #1d2429;
                            &::after,
                            &::before {
                                opacity: 0;
                            }
                        }
                        .clndr {
                            position: relative;
                            background: #1f1f21;
                            box-shadow: 0px 137px 55px rgba(25, 25, 26, 0.01),
                                0px 77px 46px rgba(25, 25, 26, 0.05),
                                0px 34px 34px rgba(25, 25, 26, 0.09),
                                0px 9px 19px rgba(25, 25, 26, 0.1),
                                0px 0px 0px rgba(25, 25, 26, 0.1);
                            .rdrDateDisplayWrapper {
                                background: #1f1f21;

                                box-shadow: 0px 137px 55px
                                        rgba(25, 25, 26, 0.01),
                                    0px 77px 46px rgba(25, 25, 26, 0.05),
                                    0px 34px 34px rgba(25, 25, 26, 0.09),
                                    0px 9px 19px rgba(25, 25, 26, 0.1),
                                    0px 0px 0px rgba(25, 25, 26, 0.1);
                                .rdrDateDisplay {
                                    .rdrDateInput {
                                        background: #292929;
                                        border-radius: 4px;
                                    }
                                }
                            }
                            .rdrMonthAndYearWrapper {
                                button {
                                    background: #292929;
                                    border-radius: 4px;
                                }
                                select {
                                    background: #292929;
                                    text-align: center;
                                    option {
                                        box-sizing: border-box;
                                        padding: 20px;
                                    }
                                }
                            }
                            * {
                                color: #fff;
                            }
                        }
                    }
                }

                .accent {
                    color: $color-accent-1;
                    margin: 0 6px;
                }
                .accent-two {
                    color: $color-accent-2;
                    margin: 0 6px;
                }
            }
            @import "./enter.scss";
            @import "./confirm.scss";
            @import "./buttons.scss";
            @import "./popUp.scss";

            @import "./userModal.scss";
        }
    }
}
