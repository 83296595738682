.admin {
    width: 100%;
    height: 100vh;
    background: #010d1a;
    display: flex;
    align-items: center;
    justify-content: center;

    .enter-container {
        width: 250px;
        box-sizing: border-box;
        color: #f5f5f5;
        background: linear-gradient(
            103.53deg,
            #000c19 -4.37%,
            rgba(13, 169, 223, 0.3) 104.99%
        );
        border-radius: 5px;
        padding: 15px 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
            width: 100%;
            margin-bottom: 15px;
            box-sizing: border-box;
            padding: 12px;
            background: linear-gradient(
                103.11deg,
                rgba(255, 255, 255, 0.084) -10.1%,
                rgba(112, 112, 112, 0.084) 110.43%
            );

            color: #fff;
            border-radius: 8px;
            border: 0;
        }
    }
}
