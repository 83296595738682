.page-mini-header {
    width: 100%;
    .width-content {
        padding-bottom: 0px;
        padding-top: 0px;
        ul {
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            list-style: none;
            width: 100%;
            border-bottom: 1px solid $container-border;

            li {
                user-select: none;
                box-sizing: border-box;
                padding: 16px;
                background: $color-text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                cursor: pointer;
                transition: 0.25s;
                overflow-x: hidden;
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background: $gradient-second;
                    bottom: 0;
                    left: 0;
                    transition: 0.35s;
                    left: -100%;
                    // opacity: 0;
                }

                &.active {
                    background: $gradient-second;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    &:after {
                        left: 0;
                        // opacity: 1;
                    }
                }
            }

            &.active {
                background: $gradient-second;
            }
        }
    }
}
