.money-flow {
    // background: red;
    .witd-list {
        width: 100%;
        position: relative;

        .filters {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 15px 0;

            position: sticky;
            top: 90px;
            z-index: 3;
            background: #000a14;

            .page-control {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
            }
            .page-filters {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 25px;

                .choose {
                    transition: 0.25s;
                    overflow: hidden;
                    max-width: 0px;

                    &.show {
                        max-width: 250px;
                    }

                    .label {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        span {
                            font-size: 14px;
                            font-weight: bold;
                            color: #fa8825;
                        }
                    }
                    button {
                        min-width: 100px;
                        background: #4c974c;
                        &::after,
                        &::before {
                            opacity: 0;
                            display: none;
                        }

                        &:hover {
                            background: rgba($color: #4c974c, $alpha: 0.5);
                        }
                    }
                }

                .inputs {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    .label {
                        font-size: 13px;
                        opacity: 0.9;
                        margin-bottom: 5px;
                    }
                }

                .own-pick {
                    position: relative;
                    .own-head {
                        width: 140px;
                        box-sizing: border-box;
                        padding: 12px;
                        border-radius: 8px;
                        border: none;
                        cursor: default;
                        background: linear-gradient(
                            103.11deg,
                            rgba(255, 255, 255, 0.084) -10.1%,
                            rgba(112, 112, 112, 0.084) 110.43%
                        );
                        outline: none;
                        font-family: "Inter", sans-serif;
                        color: #f5f5f5;
                    }
                    .own-list {
                        position: absolute;
                        left: 0;
                        top: 99%;
                        z-index: 3;

                        width: 100%;
                        background: linear-gradient(
                            103.11deg,
                            rgba(255, 255, 255, 0.084) -10.1%,
                            rgba(112, 112, 112, 0.084) 110.43%
                        );
                        backdrop-filter: blur(10px);
                        transition: 0.45s;
                        box-sizing: border-box;
                        border: 1px solid rgba($color: #fff, $alpha: 0.07);
                        border-radius: 3px;

                        opacity: 0;
                        pointer-events: none;
                        .itm {
                            box-sizing: border-box;
                            padding: 10px;
                            position: relative;
                            cursor: pointer;
                            transition: 0.25s;
                            &:hover {
                                opacity: 0.5;
                            }
                            &::after {
                                content: "";
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 100%;
                                height: 1px;
                                background: rgba($color: #fff, $alpha: 0.12);
                            }
                            &:last-child {
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                    &:hover {
                        .own-list {
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
            }
        }

        .lst-holder {
            width: 100%;
            overflow: auto;
        }
        .lst {
            width: 100%;
            font-size: 16px;
            // border-collapse: collapse;
            border-spacing: 0 20px;
            min-width: 1000px;

            tr {
                // background: blue;

                box-sizing: border-box;

                background: rgba(255, 255, 255, 0.084);

                // &.loading_item {
                //     background: red;
                // }
                // background-color: #e0e0e0;
                // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
                // color: #222;
                // &:nth-child(odd) {
                //     background: red;
                // }
                td {
                    padding: 25px 10px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    &.PENDING {
                        color: #fa8825;
                    }
                    &.APPROVED {
                        color: #4c974c;
                    }
                    &.DECLINED {
                        color: #fa5456;
                    }
                    &:nth-child(2) {
                        max-width: 120px;
                    }
                    &:nth-child(4) {
                        // max-width: 120px;
                        min-width: 120px;
                    }
                    &.clk {
                    }

                    .own-check {
                        width: 25px;
                        height: 25px;
                        border-radius: 5px;
                        background: #fff;
                        transition: 0.25s;
                        position: relative;
                        overflow: hidden;
                        cursor: pointer;

                        &::after {
                            content: "";
                            position: absolute;
                            top: 80%;
                            left: 50%;
                            // translateY(-50%)
                            transform: rotate(-50deg);
                            width: 20px;
                            height: 2px;
                            background: #fff;
                            transform-origin: center left;
                            transition: 0.25s;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            top: 80%;
                            transition: 0.25s;
                            right: 50%;
                            // translateY(-50%)
                            transform: rotate(50deg);
                            width: 10px;
                            height: 2px;
                            background: #fff;
                            transform-origin: center right;
                        }

                        &.checked {
                            background: #4c974c;
                        }
                    }
                }
            }

            thead {
                // position: sticky;
                // top: 90px;
                z-index: 2;
                tr {
                    font-weight: bold;
                    // background: hsla(0, 0%, 100%, 0.084);
                    background: #151c26;
                }
            }

            tbody {
                cursor: default;

                tr {
                    filter: blur(0px);
                    transition: 0.25s;
                    position: relative;
                    // &::after {
                    //     content: "";
                    //     position: absolute;
                    //     left: 0;
                    //     top: 0;
                    //     width: 100%;
                    //     height: 100%;
                    //     background: red;
                    // }
                }
                &.isFetching {
                    cursor: progress;
                    tr {
                        * {
                            pointer-events: none;
                        }
                        filter: blur(2px);
                    }
                }
            }
        }
    }
}
