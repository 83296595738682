.close-mob {
    display: none;
    background: transparent;
    z-index: 998;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: -100%;
    top: 0;
    transition: 0.25s;
}
.sidebar {
    width: 240px;
    height: 100vh;
    background: $color-main-bg;
    box-sizing: border-box;
    padding: 0 16px;
    position: sticky;
    top: 0;
    z-index: 99;

    border-right: 1px solid $container-border;
    @media (max-width: 768px) {
        position: fixed;
        transition: 0.25s;
        z-index: 999;
        left: -100%;
        top: 0;
    }

    .logo {
        box-sizing: border-box;
        padding-left: 0px;
        margin-top: 0;
        margin-bottom: 95px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        position: relative;
        // overflow: hidden;
        .bg {
            background: linear-gradient(
                112.98deg,
                #3d7cbc -4.82%,
                #0dbbdf 110.92%
            );
            filter: blur(71px);
            width: 100%;
            height: 80px;
            position: absolute;
            z-index: -1;
            left: -80px;
            top: 0;
        }
        svg {
            margin-left: -20px;
        }
    }
    .menu-head {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 16px;
    }
    .menu {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        li {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            padding: 10px 16px;
            border-radius: 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            position: relative;
            transition: 0.25s;
            cursor: pointer;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            user-select: none;

            .svgs {
                position: relative;
                width: 22px;
                height: 22px;
                margin-right: 10px;
                svg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    transition: 0.25s;

                    * {
                        transition: 0.25s;
                        // fill: transparent;
                        // stroke: #fff;
                        // stroke-width: 1px;
                    }
                    &:first-child {
                        opacity: 1;
                    }
                    &:last-child {
                        opacity: 0;
                    }
                }
            }

            &::before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 10px;
                padding: 2px; /* control the border thickness */
                background: $gradient-main;
                -webkit-mask: linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                pointer-events: none;
                transition: 0.25s;
                opacity: 0;
            }

            background: transparent;
            &:after {
                content: "";
                position: absolute;
                left: -16px;
                border-radius: 0px 8px 8px 0px;
                top: 0;
                height: 100%;
                width: 4px;
                background: $gradient-main;
                opacity: 0;
                transition: 0.25s;
            }

            &.active {
                background: $gradient-main;
                .svgs {
                    svg {
                        &:first-child {
                            opacity: 0;
                        }
                        &:last-child {
                            opacity: 1;
                        }
                    }
                }
                &:after {
                    opacity: 1;
                }
            }
            &:hover {
                &::before {
                    opacity: 1;
                }
            }
            &.inactive {
                opacity: 0.2;
                cursor: not-allowed;
            }
            // margin-bottom: ;
        }
    }
}

@media (max-width: 768px) {
    .open-side {
        left: 0;
        z-index: 949499494;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .open-side + .close-mob {
        display: block;
        background: rgba($color: #000000, $alpha: 0.3);
        left: 0;
    }
}
