header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-main-bg;
    position: sticky;
    top: 0;
    border-bottom: 1px solid $container-border;
    z-index: 99;

    .width-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        @media (max-width: 768px) {
            flex-wrap: wrap;
            position: relative;
            padding: 16px;
        }
        .sb {
            display: none;
            @media (max-width: 768px) {
                display: inline;
                width: 50%;
                order: 1;
            }
        }
        button {
            @media (max-width: 768px) {
                position: absolute;
                // opacity: 0;
                top: 16px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .head {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media (max-width: 768px) {
                width: 100%;
                order: 3;
                transition: height 0.25s, margin 0.25s 0.25s;
                font-size: 28px;
                overflow-x: auto;
                margin-top: 20px;
                .accent-two {
                    // overflow-x: auto;
                    // flex: 1 0;
                    // min-width: 100%;
                    .bl {
                        white-space: nowrap;
                        width: 100%;
                    }
                }
            }

            .title {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                button {
                    margin-left: 16px;
                    svg {
                        margin-left: 6px;
                        margin-right: 0;
                    }
                    @media (max-width: 768px) {
                        position: absolute;
                        // opacity: 0;
                        margin-left: 0;
                    }
                }
                .svg {
                    transition: 0.25s;
                    position: relative;
                    svg {
                        // width: 100%;
                        // height: 100%;
                        // object-fit: contain;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0px;
                        top: 0;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background: #ea3829;
                        opacity: 0;
                        transition: 0.25s;
                    }
                    &.new {
                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }

            svg {
                margin-right: 16px;
            }
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;

            // .notify {
            //     margin-left: 16px;
            //     box-sizing: border-box;
            //     padding: 4px 16px;
            //     background: $color-accent-2;
            //     border-radius: 100px;
            //     text-align: center;
            //     font-family: "Roboto", sans-serif;
            //     font-style: normal;
            //     font-weight: 400;
            //     font-size: 14px;
            //     line-height: 140%;
            //     z-index: 3;
            //     @media (max-width: 768px) {
            //         display: none;
            //     }
            // }
        }

        .end {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 768px) {
                width: 50%;
                order: 2;
                justify-content: flex-end;
            }
            .user {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-right: 33px;
                cursor: pointer;
                @media (max-width: 768px) {
                    margin-right: 0;
                }
                .name {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    margin-right: 16px;
                    @media (max-width: 768px) {
                        display: none;
                    }
                }
                .avatar {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 9px;
                    @media (max-width: 768px) {
                        margin-right: 0;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                svg {
                    transform: rotate(0);
                    transition: 0.25s;
                    @media (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
    }
}
