.dashboard {
    margin-top: 24px;
    .chart {
        position: relative;
        .switch-charts {
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            z-index: 3;
        }
    }
    .chart-options {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    }
}
.investments {
    .graph-settings {
        // margin-top: 20px;
        h2 {
            margin-right: auto;
        }
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
        padding: 15px 20px;
    }
    .axis-chart {
        padding-bottom: 20px;
    }
    .chrt {
        height: 450px;
    }
}
